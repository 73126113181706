import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
interface Props {
  title: string;
  description: string;
  icon: string;
}
const CardCompo = (props: Props) => {
  const { title, description, icon } = props;
  return (
    <Box sx={{ position: "relative", height: 1, display: "flex" }}>
      <Box
        component="img"
        src={icon}
        width={{ xs: 60, sm: 80 }}
        sx={{
          position: "absolute",
          left: "50%",
          top: 0,
          transform: "translate(-50%,-50%)",
        }}
      />
      <Card
        sx={{
          py: 3,
          borderRadius: "20px",
          boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >
        <CardContent>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="subtitle2" fontWeight={600} sx={{ my: 2 }}>
              {title}
            </Typography>
            <Typography variant="body2" color="text.disabled" fontWeight={500}>
              {description}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CardCompo;
