import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Fab,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import banner from "../../assets/landing_banner.png";
import banner2 from "../../assets/landing_banner2.png";
import CardCompo from "./CardCompo";
import icon1 from "../../assets/icon1.png";
import icon2 from "../../assets/icon2.png";
import icon3 from "../../assets/icon3.png";
import icon4 from "../../assets/icon4.png";
import icon5 from "../../assets/icon5.png";
import icon6 from "../../assets/icon6.png";
import videoImg from "../../assets/videoImg.jpg";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Image from "components/image";
const LandingPage = () => {
  const renderImg = (
    <Image
      src={videoImg}
      alt="about-vision"
      overlay="rgba(0, 0, 0, 0.5)"
      height={"70vh"}
      sx={{
        borderRadius: "28px",
        width: { xs: "90%", md: "70%" },
      }}
    />
  );
  const convenienceData = [
    {
      title: "Convenience",
      description:
        "Explore properties at any time, from anywhere, without the need for in-person visits.",
      icon: icon1,
    },
    {
      title: "Comprehensive Views",
      description:
        "Get a true sense of the property's layout, design, and ambiance with our interactive 360-degree tours.",
      icon: icon2,
    },
    {
      title: "Time-Saving",
      description:
        "Save time by narrowing down your property search and only visiting the properties that truly meet your needs and preferences.",
      icon: icon3,
    },
    {
      title: "Safe and Secure",
      description:
        "Enjoy peace of mind knowing that you can view properties safely and securely, without any health or safety risks.",
      icon: icon4,
    },
    {
      title: "Enhanced Decision Making",
      description:
        "Make informed decisions about properties by thoroughly exploring them through our immersive virtual tours.",
      icon: icon5,
    },
    {
      title: "Multi-Device Compatibility",
      description:
        "Access our virtual tours on a wide range of devices, including desktop computers, laptops, tablets, and smartphones, for seamless viewing anytime, anywhere.",
      icon: icon6,
    },
  ];

  const propertyInfo = [
    {
      title: "Showcase Your Property in Detail",
      description:
        "Present your property in its best light with immersive 360-degree views that allow potential buyers to explore every room, corner, and feature.",
    },
    {
      title: "Save Time and Resources",
      description:
        "Streamline the property viewing process for potential buyers by offering virtual tours with Insta Show's 360-degree views.",
    },
    {
      title: "Increase Buyer Engagement",
      description:
        "Capture the attention of potential buyers and keep them engaged with dynamic and interactive property presentations.",
    },
    {
      title: "Stand Out in the Market",
      description:
        "Differentiate your listings from the competition and stand out in today's competitive real estate market with Insta Show's innovative technology.",
    },
  ];

  return (
    <Container maxWidth="xl" sx={{ p: "0 !important" }}>
      <Box>
        <Header />
        <Box>
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "70vh",
              backgroundImage: `url(${banner})`, // Set the background image
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Stack
              px={{ xs: 4, sm: 8 }}
              gap={3}
              justifyContent="center"
              height={1}
              textAlign={{ xs: "center", sm: "inherit" }}
            >
              <Box>
                <Typography variant="h2">
                  Captivate Your Audience with
                </Typography>
                <Typography variant="h2" color="primary">
                  Immersive 360° Views.
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" fontWeight={500}>
                  Step into the future of property showcasing and experience the
                  <br />
                  difference with InstaShow. Join us today and transform the way
                  <br />
                  you showcase your properties!
                  <br />
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                }}
              >
                <Box>
                  <Typography variant="h4">19K+</Typography>
                  <Typography fontWeight={500}>Satisfied Customer</Typography>
                </Box>
                <Box>
                  <Typography variant="h4">25K+</Typography>
                  <Typography fontWeight={500}>Years of Experience</Typography>
                </Box>
                <Box>
                  <Typography variant="h4">120+</Typography>
                  <Typography fontWeight={500}>Property Collections</Typography>
                </Box>
              </Box>
              <Box>
                <Button variant="contained" size="large" sx={{ px: 5 }}>
                  Join Now!
                </Button>
              </Box>
            </Stack>
          </Box>
          <Box
            sx={{
              my: 11,
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h2">
              Why Choose Our Virtual Tours?
              <Divider
                sx={{
                  border: "3px solid black",
                  borderColor: "primary.main",
                  borderRadius: "40px",
                  width: "30%",
                  mx: "auto",
                }}
              />
            </Typography>
          </Box>
          <Box mt={12} px={{ xs: 4, md: 8 }}>
            <Grid container spacing={5} rowGap={3} alignItems="stretch">
              {convenienceData?.map((ele, ind) => (
                <Grid item xs={12} sm={6} md={4} key={ind}>
                  <CardCompo
                    title={ele.title}
                    description={ele.description}
                    icon={ele.icon}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box textAlign="center">
            <Typography variant="h2" mt={11} width={"90%"} mx="auto">
              Let's Transform Your Property Showcase with Our <br /> Innovative
              Solutions
              <Divider
                sx={{
                  border: "3px solid black",
                  borderColor: "secondary.main",
                  borderRadius: "40px",
                  width: "30%",
                  mx: "auto",
                }}
              />
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 5,
              borderRadius: 3,
              display: "flex",
              overflow: "hidden",
              position: "relative",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {renderImg}

            <Fab sx={{ position: "absolute", zIndex: 9 }}>
              <PlayArrowIcon />
            </Fab>
          </Box>
          <Box
            my={4}
            sx={{
              textAlign: "center",
              width: { xs: "90%", md: "70%" },
              mx: "auto",
            }}
          >
            <Typography variant="body1" sx={{ color: "gray" }}>
              we take pride in presenting your property to potential buyers in
              the most captivating and engaging way <br /> possible.Our
              immersive 3D virtual tours allow prospective buyers to explore
              every room, corner, and feature <br /> of your property as if they
              were there in person.
            </Typography>
          </Box>
          <Box
            sx={{
              my: 11,
              position: "relative",
              width: { xs: "90%", md: "70%" },
              // height: "650px",
              mx: "auto",
              backgroundImage: { md: `url(${banner2})` }, // Set the background image
              backgroundSize: "cover",
              backgroundPosition: "center",
              boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px",
              borderRadius: "20px",
            }}
          >
            <Box py={4} px={5}>
              <Typography variant="h4">
                Attract More Buyers with Captivating 360-Degree Views
              </Typography>
              <Typography variant="body2" color="gray" mt={1}>
                As a property owner, you have the opportunity to elevate your
                listings and attract more potential <br /> buyers with Insta
                Show's 360-degree views.
              </Typography>
              <ul style={{ padding: "0 16px" }}>
                <>
                  {propertyInfo.map((ele) => (
                    <Box mt={4} maxWidth={400}>
                      <Typography variant="h6">
                        <li>{ele.title}</li>
                      </Typography>
                      <Typography color="gray" variant="body2">
                        <li style={{ listStyle: "none" }}>{ele.description}</li>
                      </Typography>
                    </Box>
                  ))}
                </>
              </ul>
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </Container>
  );
};

export default LandingPage;
