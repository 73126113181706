import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import logo from "../../assets/logo.png";
import TelegramIcon from "@mui/icons-material/Telegram";
import playStore from "../../assets/playStore.png";
import appleStore from "../../assets/appleStore.png";
import { inherits } from "util";
const Footer = () => {
  return (
    <Box sx={{ borderTop: "2px solid ", borderColor: "secondary.main" }}>
      <Box
        sx={{
          px: { xs: 4, md: 8 },
          py: { xs: 2, md: 4 },
          display: "flex",
          justifyContent: "space-between",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{ textAlign: { xs: "center", sm: "inherit" } }}
        >
          <Grid item xs={12} sm={6} md={4} order={1}>
            <Box>
              <Box component="img" src={logo} height={40} />
              <Typography fontWeight={600} sx={{ mt: 1 }}>
                Address
              </Typography>
              <Typography sx={{ mt: 1 }} variant="body2" color="gray">
                789 Queensberry Street, North Melbourne <br /> VIC 3051, Dubai.
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            order={{ xs: 3, md: 2 }}
            sx={{ display: { xs: "none", sm: "inherit" } }}
          >
            <Box>
              <Typography fontWeight={600} variant="h6">
                Popular Search
              </Typography>
              <Typography sx={{ mt: 1 }} variant="body2" color="gray">
                Apartment for Sale
              </Typography>
              <Typography sx={{ mt: 1 }} variant="body2" color="gray">
                Apartment for Rent
              </Typography>
              <Typography sx={{ mt: 1 }} variant="body2" color="gray">
                Offices for Sale
              </Typography>
              <Typography sx={{ mt: 1 }} variant="body2" color="gray">
                Offices for Rent
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            order={{ xs: 4, md: 3 }}
            sx={{ display: { xs: "none", sm: "inherit" } }}
          >
            <Box>
              <Typography fontWeight={600} variant="h6">
                Quick Links
              </Typography>
              <Typography sx={{ mt: 1 }} variant="body2" color="gray">
                Terms of Use
              </Typography>
              <Typography sx={{ mt: 1 }} variant="body2" color="gray">
                Privacy Policy
              </Typography>
              <Typography sx={{ mt: 1 }} variant="body2" color="gray">
                Pricing Plans
              </Typography>
              <Typography sx={{ mt: 1 }} variant="body2" color="gray">
                Our Services
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4} order={{ xs: 2, md: 4 }}>
            <Box>
              <Typography
                fontWeight={600}
                variant="h6"
                sx={{ mt: { xs: 2, md: 0 } }}
              >
                Keep Yourself Up to Date
              </Typography>
              <TextField
                placeholder="Your email"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{
                          backgroundColor: "primary.main",
                          borderRadius: 2,
                        }}
                      >
                        <TelegramIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: { paddingRight: 5 },
                }}
                sx={{
                  mt: 1,
                  color: "black",
                  "& .MuiInputBase-input": {
                    color: "#000",
                    textIndent: "7px",
                    py: 2,
                  },
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", sm: "space-evenly" },

                  mt: 1.5,
                  maxWidth: { xs: "unset", sm: 240 },
                }}
              >
                <Box
                  component="img"
                  src={playStore}
                  width={"48%"}
                  sx={{ maxWidth: 180 }}
                />
                <Box
                  component="img"
                  src={appleStore}
                  width={"48%"}
                  sx={{ maxWidth: 180 }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Footer;
