import { createTheme } from "@mui/material";
import { red } from "@mui/material/colors";

// const colors = {
//   primary: '#06C7BB',
//   secondary: '#06C7BB',
//   light: '#484848',
//   lighter: '#F6F6F6',
//   white: '#464646',
//   black: '#000000'
// };
// Create a theme instance.
let theme = createTheme({
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 567,
  //     md: 768,
  //     lg: 992,
  //     xl: 1440
  //   }
  // },
  palette: {
    common: {
      black: "#000000",
      white: "#464646",
      grey: "#D9D9D9",
      darkBlue: "#003260",
      lightBlue: "#D9EDFF",
      pageBackground: "#FBFBFF",
      green: "#479706",
      orange: "#FBBF24",
      borderColor: "#2f2f2f",
    },

    primary: {
      main: "#3E8370",
      contrastText: "#fff",
    },
    secondary: {
      main: "#AADDE1",
      contrastText: "#fff",
    },
    text: {
      primary: "#AADDE1",
    },
    error: {
      main: red.A700,
    },
  },
  typography: {
    fontFamily: "Inter",
    color: "#464646",
    lineHeight: 1.3,
    fontWeightRegular: "400",
    fontWeightMedium: "500",
    fontWeightSemibold: "600",
    fontWeightBold: "700",
  },
});

theme = createTheme({
  ...theme,

  typography: {
    h1: {
      fontWeight: "600",
      fontSize: "48px",
      lineHeight: "64px",
      letterSpacing: "normal",
      color: "#464646",
      [theme.breakpoints.down("md")]: {
        fontSize: "36px",
        lineHeight: "48px",
      },
    },
    h2: {
      fontWeight: "600",
      fontSize: "36px",
      lineHeight: "48px",
      letterSpacing: "normal",
      color: "#464646",
      [theme.breakpoints.down("md")]: {
        fontSize: "28px",
        lineHeight: "36px",
      },
    },
    h3: {
      fontWeight: "600",
      fontSize: "28px",
      lineHeight: "36px",
      letterSpacing: "normal",
      color: "#464646",
      [theme.breakpoints.down("md")]: {
        fontSize: "24px",
        lineHeight: "32px",
      },
    },
    h4: {
      fontWeight: "600",
      fontSize: "24px",
      lineHeight: "32px",
      letterSpacing: "normal",
      color: "#464646",
      [theme.breakpoints.down("md")]: {
        fontSize: "20px",
        lineHeight: "28px",
      },
    },
    h5: {
      fontWeight: "600",
      fontSize: "20px",
      lineHeight: "28px",
      letterSpacing: "normal",
      color: "#464646",
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
        lineHeight: "24px",
      },
    },
    h6: {
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "24px",
      letterSpacing: "normal",
      color: "#464646",
      [theme.breakpoints.down("md")]: {
        fontSize: "16px",
        lineHeight: "20px",
      },
    },
    subtitle1: {
      fontFamily: "Inter",
      fontWeight: "500",
      fontSize: "24px",
      lineHeight: "28px",
      letterSpacing: "normal",
      color: "#464646",
    },
    subtitle2: {
      fontFamily: "Inter",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "22px",
      letterSpacing: "normal",
      color: "#464646",
    },
    body1: {
      fontFamily: "Inter",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "20px",
      color: "#464646",
    },
    body2: {
      fontFamily: "Inter",
      fontWeight: "400",
      fontSize: "14px",
      color: "#464646",
    },
    button: {
      fontFamily: "Inter",
      fontWeight: "400",
    },

    caption: {
      fontFamily: "Inter",
      fontWeight: "400",
      fontSize: "12px",
      color: "#464646",
    },
    overline: {
      fontFamily: "Inter",
      fontWeight: "400",
      color: "#464646",
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // Styles for the "contained" variant
        contained: {
          borderRadius: "48px", // Example: Set border radius for contained variant
          textTransform: "none", // Example: Remove text transformation
          // Add other styles for the contained variant
        },
        outlined: {
          borderRadius: "48px", // Example: Set border radius for contained variant
          textTransform: "none", // Example: Remove text transformation
          // Add other styles for the contained variant
        },
        text: {
          color: "black",
          "&:hover": {
            backgroundColor: "#c9dcd7",
          },
          borderRadius: "48px", // Example: Set border radius for contained variant
          textTransform: "none", // Example: Remove text transformation
          // Add other styles for the contained variant
          fontWeight: "500",
        },
      },
    },
  },
});

export default theme;
