import {
  Box,
  Button,
  Drawer,
  IconButton,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import logo from "../../assets/logo.png";
import MenuIcon from "@mui/icons-material/Menu";

const Header = () => {
  const [open, setOpen] = useState(false);
  return (
    <Box>
      <Box
        sx={{
          p: { xs: "12px 16px", sm: "20px 34px" },
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box component="img" src={logo} sx={{ height: { xs: 30, sm: 40 } }} />

        <IconButton
          onClick={() => setOpen(true)}
          sx={{ display: { xs: "inherit", sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Stack
          direction="row"
          display={{ xs: "none", sm: "flex" }}
          gap={1.5}
          justifyContent="center"
          alignItems="center"
        >
          <Button>Home</Button>
          <Button>Careers</Button>
          <Button>Blog</Button>
          <Button>About Us</Button>
          <Button variant="contained" sx={{ px: 3 }}>
            Login
          </Button>
        </Stack>
        <Drawer open={open} onClose={() => setOpen(false)} anchor="right">
          <Stack
            sx={{
              width: 150,
              px: 2,
              py: 2,
              gap: 1,
              fontSize: { variant: "caption" },
            }}
          >
            <MenuItem>Home</MenuItem>
            <MenuItem>Careers</MenuItem>
            <MenuItem>Blog</MenuItem>
            <MenuItem>About Us</MenuItem>
            <Button variant="contained" sx={{ mt: 2 }}>
              Login
            </Button>
          </Stack>
        </Drawer>
      </Box>
    </Box>
  );
};

export default Header;
